import React from 'react'
import PropTypes from 'prop-types'
// import Img from 'gatsby-image'
import Content from './Content'
// import {HTMLContent} from './Content'
// import remark from 'remark'
// import remarkHtml from 'remark-html'

const BoardPageTemplate = ({ title, notice, board, content, disclosure, activeConflicts, contentComponent}) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>

              <Notice notice={notice} />
              <PageContent className="content" content={content} />

              <hr />
              {board.map(director => (
                <div key={director.name}>
                {/* <div className="card" style={{ marginBottom: '1rem'}}> */}
                  {/* <div className="card-content"> */}
                  <div className="media" style={{ marginBottom: '2rem'}}>
                    <div className="media-content">
                    <p  className="is-size-5">{director.name}<br /><small className="has-text-grey">{director.role} - <span style={{ fontSize: '0.8rem' }}>Term ends: {director.term}</span></small></p>
                    <div className="content">
                      {/* <HTMLContent content={remark().use(remarkHtml).processSync(director.bio).toString()}/> */}
                    </div>
                  </div>
                  </div>
                    <hr />
                  {/* </div> */}
                {/* </div> */}
                </div>
              ))}

              <h3 className="is-size-3">Conflicts Disclosure Statements:</h3>
              {(activeConflicts) 
              ? <p><a href={disclosure.publicURL} className="button">View disclosure statement(s)</a></p> 
              : <p>No conflicts to report for the current Board of Directors.</p> }

              {/* <h2 className="is-size-2">Election Information</h2>

              <p>The next directors election will be held on November 3, 2020. Polling locations will be open on election day from 7:00am to 7:00pm.  The period for early voting by personal appearance will be October 19, 2020 through October 30, 2020.  Exact times and dates for early voting by personal appearance during such period will be provided at a later date.  Polling locations for early voting by personal appearance and election day will also be provided at a later date.</p>
              <p>The first day to file an application for a place on the ballot was January 15, 2020. The last day to file an application for a place on the ballot was February 14, 2020. Please see the Texas Secretary of State website for additional election deadlines.</p>
              <p>Please see Section 54.102, Texas Water Code, and 141.001, Texas Election Code, for the eligibility requirements to serve as a director. Candidate filing materials can be found on the Texas Secretary of State and the Texas Ethics Commission websites.</p> */}

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BoardPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BoardPageTemplate
